var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  !_vm.isAccessDenied
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              _vm.language.path.length === 0 ||
                              _vm.language.name === null ||
                              _vm.language.locale === null,
                            size: "sm",
                            variant: "success",
                          },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_add_entity",
                          [_vm.$t("fields.title").toLowerCase()]
                        )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("language.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "language.field.name"
                                            ),
                                            "data-vv-name":
                                              "language.field.name",
                                            maxlength: _vm.maxNameLength,
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            autofocus: true,
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "language.field.name"
                                              ),
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.language.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.language,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "language.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "language.field.name"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { staticClass: "pr-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t("language.field.description"),
                                  "label-for": "language-description",
                                },
                              },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    id: "language-description",
                                    placeholder: _vm.isDescriptionReadOnly
                                      ? ""
                                      : _vm.$t("placeholder.description"),
                                    "data-vv-as": _vm.$t(
                                      "language.field.description"
                                    ),
                                    "data-vv-name": "language.description",
                                    "data-vv-delay": "500",
                                    "max-rows": 6,
                                    readonly: _vm.isDescriptionReadOnly,
                                    trim: "",
                                    rows: 3,
                                  },
                                  model: {
                                    value: _vm.language.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.language, "description", $$v)
                                    },
                                    expression: "language.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isLocaleVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("language.field.locale"),
                                      "label-for": "display_name",
                                    },
                                  },
                                  [
                                    _c("multiselect", {
                                      staticClass:
                                        "custom-dropdown-options enable-option-icon",
                                      attrs: {
                                        "max-height": 300,
                                        options: _vm.localeOptions.map(
                                          (i) => i.value
                                        ),
                                        "custom-label":
                                          _vm.getLocaleOptionLabel,
                                        placeholder: "",
                                        searchable: false,
                                        "allow-empty": true,
                                        showLabels: false,
                                        disabled: _vm.isNameReadOnly,
                                      },
                                      nativeOn: {
                                        keydown: function ($event) {
                                          return _vm.keydownHandler.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "option",
                                            fn: function (props) {
                                              return [
                                                _vm.language.locale ==
                                                props.option
                                                  ? _c("font-awesome-icon", {
                                                      staticClass:
                                                        "selected-option-icon",
                                                      attrs: {
                                                        icon: ["far", "check"],
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "option__title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocaleOptionLabel(
                                                          props.option
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3853077903
                                      ),
                                      model: {
                                        value: _vm.language.locale,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.language, "locale", $$v)
                                        },
                                        expression: "language.locale",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showLocaleError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "language.field.locale"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { staticClass: "pr-0", attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { staticClass: "mr-1" }, [
                                  _vm._v(_vm._s(_vm.$t(`language.field.path`))),
                                ]),
                                !_vm.isStorageFileReadOnly
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-action",
                                        attrs: {
                                          id: "FILE_ADD",
                                          disabled: _vm.isStorageFileReadOnly,
                                        },
                                        on: { click: _vm.fileSelectorToggle },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "plus"] },
                                        }),
                                        _c("b-popover", {
                                          attrs: {
                                            target: "FILE_ADD",
                                            placement: "top",
                                            triggers: "hover",
                                            content: _vm.$t(
                                              "task.button.file_add"
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("BadgeGroup", {
                                  attrs: {
                                    readOnly: _vm.isStorageFileReadOnly,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ item, index }) {
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text: item.name,
                                              variant: "primary",
                                              pillable: !!item.pillable,
                                              readOnly:
                                                _vm.isStorageFileReadOnly,
                                              enableClickWhenReadOnly: "",
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.fileBadgeRemove(
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.fileBadgeClick(item)
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.language.path,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.language, "path", $$v)
                                    },
                                    expression: "language.path",
                                  },
                                }),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    staticClass:
                                      "alert-danger form-field-alert",
                                    class: { "d-block": _vm.showPathError },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "circle-exclamation"],
                                      },
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(
                                          _vm.errors.first(
                                            "language.field.path"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c(
                            "div",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "d-inline-block",
                                  model: {
                                    value: _vm.language.default,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.language, "default", $$v)
                                    },
                                    expression: "language.default",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.default_lang")))]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.state.fileSelectorShow
        ? _c("FileSelectorModal", {
            attrs: { multiple: false, show: _vm.state.fileSelectorShow },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "fileSelectorShow", $event)
              },
              ok: _vm.fileSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }