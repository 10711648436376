<template>
  <div id="REBATE_BADGE_GROUP_MODAL" style="height: 100%; width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Rebate"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`project.field.rebates`) }}</label>
        <button class="btn-action" @click="rebateSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="rebates">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="rebateBadgeRemove(index)" @badgeClick="rebateBadgeClick(item.uuId)"
              :text="`${item.name} ${formatRebate(item.rebate)}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- rebate selector -->
    <GenericSelectorModalForAdmin v-if="rebateSelectorShow"
      :show.sync="rebateSelectorShow" 
      :entityService="rebateUtil" 
      entity="REBATE"
      :preselected="rebateEditId"
      nonAdmin
      @cancel="rebateSelectorCancel"
      @ok="rebateSelectorOk"
    />
  </div>
</template>

<script>
import { objectClone } from '@/helpers'
import { toFixed } from '@/helpers/task-duration-process'
import { rebateUtil } from '@/views/management/script/rebate';
export default {
  name: 'RebateBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
    
  },
  props: {
    show: { type: Boolean, required: true }
    , rebateList: { type: Array, default: () => [] }
  },
  data() {
    return {
      modalShow: false
      , rebateSelectorShow: false
      , rebates: []
      , rebateEditId: null
    }
  },
  created() {
    this.modifiedList = []
    this.rebateUtil = rebateUtil
  },
  beforeMount() {
    this.rebates.splice(0, this.rebates.length, ...this.rebateList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
    this.rebateUtil = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.rebates.splice(0, this.rebates.length, ...this.rebateList)
        }
      }
    }
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , rebateSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.rebateSelectorShow = false
    }
    , rebateBadgeRemove: function(index) {
      this.rebates.splice(index,1)
    }
    , rebateBadgeClick: function(id) {
      this.rebateEditId = id
      this.rebateSelectorShow = true
    }
    , rebateSelectorToggle() {
      this.rebateEditId = null;
      this.rebateSelectorShow = true
    }
    , rebateSelectorOk({ details }) {
      const rebates = JSON.parse(JSON.stringify(this.rebates));
      
      if (details.length > 0) {
        const newRebatesToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = rebates.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const rebate = rebates[foundIndex];
            rebate.uuId = r.uuId;
            rebate.name = r.name;
            rebate.rebate = r.rebate
            newRebatesToReplacePreselected.push(rebate);
            rebates.splice(foundIndex, 1);
          } else {
            newRebatesToReplacePreselected.push({ uuId: r.uuId, name: r.name, rebate: r.rebate })
          }
        }
        
        if (this.rebateEditId != null) {
          const foundIndex = rebates.findIndex(i => i.uuId == this.rebateEditId);
          if (foundIndex != -1) {
            rebates.splice(foundIndex, 1, ...newRebatesToReplacePreselected);
          } else {
            rebates.push(...newRebatesToReplacePreselected);
          }
        } else {
          rebates.push(...newRebatesToReplacePreselected);
        }
      }  else if (this.rebateEditId != null) {
        //When no selection is made, remove the preselected one from the existing rebates
        rebates.splice(rebates.findIndex(j => j.uuId === this.rebateEditId), 1);
      }
      this.rebates.splice(0, this.rebates.length, ...rebates);

      this.rebateEditId = null;
    }
    , ok() {
      this.$emit('ok', objectClone(this.rebates))
      this.$emit('update:show', false)
    }
    , formatRebate(val) {
      if (isNaN(val)) {
        return ''
      }
      return ` (${toFixed(val * 100, 0)}%)`
    }
  }
}
</script>